<template>
  <div>
    <lead-info-card
      :lead="lead"
      :publishing="publishing"
      @onPublish="publishLead"
      @onHide="removeFromFeed"
      @onDelete="deleteLead"
      @onSave="onSave"
    />

    <h3 class="my-1">
      Отклики учителей
    </h3>
    <lead-replies
      :lead-id="leadId"
      :can-accept="!isDone"
      @onAccept="getLead"
    />
  </div>
</template>

<script>

import LeadInfoCard from '../../../components/leads/lead_page/LeadInfoCard.vue'
import LeadReplies from '../../../components/leads/lead_page/LeadReplies.vue'
import { LEAD_STATUS_DONE } from '../../../shared/constants'

export default {
  name: 'AdminLead',
  components: { LeadReplies, LeadInfoCard },
  data: () => ({
    lead: null,
    loading: false,
    publishing: false,
  }),
  computed: {
    leadId() {
      return this.$route.params.lead_id
    },
    isDone() {
      return this.lead?.status === LEAD_STATUS_DONE
    },
  },
  async created() {
    this.$eventBus.$emit('page-title', 'Загрузка заявки...')
    await this.getLead()
  },
  methods: {
    onSave(lead) {
      this.lead = { ...lead }
    },
    async getLead() {
      this.loading = true
      this.lead = await this.$chatsHttp.get(`/admin/leads/${this.leadId}`)
      this.$eventBus.$emit('page-title', `Заявка №${this.lead.displayed_id}`)
      this.loading = false
    },
    async publishLead() {
      this.publishing = true
      await this.$chatsHttp.put(`/admin/leads/${this.leadId}/publish`).catch(() => {})
      this.lead.published = true
      this.publishing = false
    },
    async removeFromFeed() {
      this.publishing = true
      await this.$chatsHttp.put(`/admin/leads/${this.leadId}/hide`).catch(() => {})
      this.lead.published = false
      this.publishing = false
    },
    async deleteLead() {
      this.publishing = true
      await this.$chatsHttp.delete(`/admin/leads/${this.leadId}`).catch(() => {})
      this.publishing = false
      await this.$router.push({ name: 'cabinet.admin.leads' })
    },
  },
}
</script>

<style scoped>

</style>
