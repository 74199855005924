<template>
  <b-card class="full-height mb-0">
    <div class="d-flex align-items-center mb-1">
      <feather-icon
        v-if="reply.accepted"
        icon="CheckCircleIcon"
        size="18"
        class="mr-50 text-success"
      />
      <p class="font-small-3 font-weight-bold text-muted mb-0">
        №{{ reply.id }}
      </p>
      <div class="flex-fill" />
      <span class="text-muted font-small-3">{{ reply.created | dateTime }}</span>
    </div>

    <div class="d-flex align-items-center">
      <div class="flex-fill">
        <p class="mb-0 font-medium-1 font-weight-bold text-body">
          {{ teacher.full_name }}
        </p>
        <p class="mb-0 font-small-3 text-muted">
          +{{ teacher.phone }}
        </p>
      </div>

      <b-button
        id="profile-popover"
        class="btn-icon"
        variant="flat-info"
        size="sm"
        title="Профиль учителя"
      >
        <feather-icon
          size="20"
          icon="InfoIcon"
        />
      </b-button>

      <b-popover
        ref="profilePopover"
        target="profile-popover"
        triggers="hover"
        placement="topright"
        custom-class="p-0"
      >
        <b-card class="mb-0">
          <div class="d-flex align-items-center mb-1">
            <b-avatar :src="teacher.avatar" />
            <div class="flex-fill ml-50">
              <p class="mb-0 font-medium-1 font-weight-bold text-body">
                {{ teacher.full_name }}
              </p>
              <p
                class="font-small-3 mb-0"
                :class="isVerified ? 'text-success' : 'text-danger'"
              >
                <feather-icon :icon="isVerified ? 'CheckIcon' : 'XIcon'" />
                {{ isVerified ? 'Верифицирован' : 'Не верифицирован' }}
              </p>
            </div>
          </div>

          <div class="font-small-3">
            <p class="font-weight-bold mb-0">
              О себе
            </p>
            <p class="font-small-3 mb-0">
              {{ teacherProfile.description }}
            </p>
            <p class="font-small-3 mb-0">
              Возраст: {{ teacherProfile.age }}
            </p>
            <p class="font-small-3">
              Пол: {{ teacher.gender ? 'Мужской' : 'Женский' }}
            </p>

            <p class="font-weight-bold mb-0">
              От администратора
            </p>
            <p class="font-small-3">
              {{ teacherProfile.admin_comment }}
            </p>

            <p class="font-weight-bold mb-0">
              Комиссия
            </p>
            <p class="font-small-3">
              {{ teacherProfile.commission_fee || 0 }}%
            </p>

            <p class="font-weight-bold mb-0">
              Образование
            </p>
            <p class="font-small-3">
              {{ diplomas }}
            </p>

            <p class="font-weight-bold mb-0">
              Контакты
            </p>
            <a
              :href="`tel:${teacher.phone}`"
              class="font-small-3"
            >
              +{{ teacher.phone }}
            </a>
            <a
              v-if="teacher.skype"
              :href="`mailto:${teacher.email}`"
              class="font-small-3"
            >
              {{ teacher.email }}
            </a>
            <p
              v-if="teacher.skype"
              class="font-small-3"
            >
              Skype: {{ teacher.skype }}
            </p>
          </div>

        </b-card>
      </b-popover>
    </div>

    <b-alert
      show
      class="mt-1"
      :variant="hasPrice ? 'info' : 'warning'"
    >
      <div class="alert-body">
        <span v-if="hasPrice">Стоимость занятия: <b>{{ reply.lesson_price }}</b> руб.</span>
        <span v-else>Стоимость занятия не определена</span>
      </div>
    </b-alert>

    <div v-if="reply.comment">
      <p class="mb-0 text-muted font-small-3">
        Комментарий
      </p>
      <p class="text-body">
        {{ reply.comment }}
      </p>
    </div>

    <div class="thin-divider my-1" />
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <b-button
          v-if="canAccept"
          variant="flat-success"
          size="sm"
          :disabled="!hasPrice"
          @click="acceptReply"
        >
          <b-spinner
            v-if="accepting"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="CheckIcon"
            class="mr-50"
          />
          <span v-if="accepting">В процессе...</span>
          <span v-else>Выбрать</span>
        </b-button>
      </div>
      <div class="flex-fill" />
      <lead-reply-chat-history
        v-if="reply.chat"
        :reply="reply"
      >
        <template #activator="{show}">
          <b-button
            variant="flat-primary"
            size="sm"
            @click="() => { resetUnread(); show() }"
          >
            <div class="d-flex align-items-center">
              <b-badge
                pill
                :variant="unreadMessages ? 'primary' : 'light-primary'"
                class="mr-50 font-small-2"
              >
                <feather-icon icon="MessageSquareIcon" />
                <span v-if="unreadMessages"> {{ unreadMessages }}</span>
              </b-badge>
              <span>Чат</span>
            </div>
          </b-button>
        </template>
      </lead-reply-chat-history>
      <b-button
        v-else
        variant="flat-primary"
        size="sm"
        @click="createChat"
      >
        <b-spinner
          v-if="creatingChat"
          class="mr-50"
          small
        />
        <feather-icon
          v-else
          icon="PlusIcon"
          class="mr-50"
        />
        <span v-if="creatingChat">Создание...</span>
        <span v-else>Создать чат</span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BBadge, BSpinner, BAlert, BPopover, BAvatar,
} from 'bootstrap-vue'
import LeadReplyChatHistory from './LeadReplyChatHistory.vue'

export default {
  name: 'LeadReplyCard',
  components: {
    LeadReplyChatHistory,
    BCard,
    BButton,
    BBadge,
    BSpinner,
    BAlert,
    BPopover,
    BAvatar,
  },
  props: {
    reply: {
      type: Object,
      required: true,
    },
    canAccept: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    creatingChat: false,
    accepting: false,
  }),
  computed: {
    hasPrice() {
      return !!this.reply.lesson_price
    },
    teacher() {
      return this.reply.user
    },
    teacherProfile() {
      return this.teacher?.teacher ?? {}
    },
    unreadMessages() {
      return this.reply.chat && this.reply.chat.unread_number
    },
    isVerified() {
      return this.teacherProfile.status === 'verified'
    },
    diplomas() {
      let diplomas = []
      try {
        diplomas = JSON.parse(this.teacherProfile.diplomas)
      } catch (err) {
        diplomas = this.teacherProfile.diplomas
      }

      return diplomas.join(', ')
    },
  },
  methods: {
    async acceptReply() {
      this.accepting = true
      const reply = await this.$chatsHttp.post(`/admin/lead-replies/${this.reply.id}/accept`)
      this.$emit('onAccept', reply)
      this.accepting = false
    },
    resetUnread() {
      if (this.unreadMessages) this.reply.chat.unread_number = 0
    },
    async createChat() {
      this.creatingChat = true
      this.reply.chat = await this.$chatsHttp.post(`/admin/lead-replies/${this.reply.id}/create-chat`)
      this.creatingChat = false
    },
  },
}
</script>

<style scoped>

</style>
