<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      :title="`Редактирование заявки №${lead.displayed_id}`"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template #default="{ hide }">
        <!-- form -->
        <b-form @submit.prevent="saveLead">

          <div class="p-2">

            <!-- name -->
            <b-form-group
              label-for="text"
              label="Комментарий (необязательно)"
            >
              <b-form-textarea
                id="url"
                v-model.trim="form.comment"
                name="name"
                rows="3"
                placeholder="Введите текст комментария"
                autofocus
              />
            </b-form-group>
          </div>
          <!-- submit button -->
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
            >
              Сохранить
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="hide"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormTextarea, BModal,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { toast } from '../../../helpers'

export default {
  name: 'EditLeadModal',
  components: {
    BModal,
    BButton,
    BForm,
    BFormTextarea,
    BFormGroup,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {},
    display: false,
    sending: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  methods: {
    async saveLead() {
      const { comment } = this.form

      this.sending = true
      await this.$chatsHttp.put(`/admin/leads/${this.lead.id}`, { comment })
        .then(response => {
          toast({ title: 'Отклик сохранен', type: 'success' })
          this.$emit('onSave', response)
          this.display = false
        }).catch(err => {
          toast({ title: 'Ошибка сохранения', text: err?.data?.message, type: 'danger' })
          this.display = false
        })
      this.sending = false
    },
    show() {
      this.display = true
      this.form = { ...this.lead }
    },
  },
}
</script>

<style scoped>

</style>
